@use './abstract' as *

.footer
	position: relative
	display: flex
	flex-direction: column
	
	user-select: none
	
	.links
		padding: 1rem 4rem
		background-color: $z-color-black
		color: white
		
		display: flex
		flex-direction: row
		gap: 2rem
		
		// Font
		@include z-metric(14px, 32px, 0)
		
		span
			cursor: pointer
			font-size: 14px
			line-height: 2rem
			color: $z-color-light-grey
	
	@include zi-media
		display: none
