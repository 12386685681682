.world_canvas
	position: absolute
	inset: 0
	width: 100%
	height: 100%
	
	opacity: 1
	transition: opacity .3s ease
	
	&.hidden
		opacity: 0
		pointer-events: none !important
