@use '../../../abstract/index' as *

.select_room_overlay
	position: absolute
	bottom: 6.5rem + 5rem
	right: 1rem
	
	overflow: hidden
	
	display: flex
	flex-direction: row
	
	border-radius: 10px
	padding: 1rem
	
	box-shadow: 0 0 0 2px $z-color-black
	background-color: white
	
	.icon
		font-size: 24px
	
	@include zi-media('>')
		display: none
