/* Character Styles */
@mixin z-font($font, $font-size: 1rem, $line-height: 1em, $letter-spacing: auto)
	font-family: $font, sans-serif
	font-size: $font-size
	line-height: $line-height
	letter-spacing: #{$letter-spacing/100}px

@mixin z-metric($font-size: 1rem, $line-height: 1em, $letter-spacing: auto)
	@include z-font(Metric, $font-size, $line-height, $letter-spacing)

@mixin z-nexity($font-size: 1rem, $line-height: 1em, $letter-spacing: auto)
	@include z-font(Nexity, $font-size, $line-height, $letter-spacing)

@mixin z-metric-14-14-70
	@include z-metric
	font-style: normal
	font-weight: 400
	font-size: 14px
	line-height: 14px
	letter-spacing: .7px

@mixin z-metric-14-16-35
	@include z-metric
	font-style: normal
	font-weight: 400
	font-size: 14px
	line-height: 14px
	letter-spacing: .35px

@mixin z-metric-18
	@include z-metric
	font-style: normal
	font-weight: 400
	font-size: 18px
	line-height: 24px
	letter-spacing: .45px

@mixin z-metric-24
	@include z-metric
	font-style: normal
	font-weight: 400
	font-size: 24px
	line-height: 24px
	letter-spacing: 0

@mixin z-metric-16
	@include z-metric
	font-style: normal
	font-weight: 400
	font-size: 16px
	line-height: 16px
	letter-spacing: .4px

@mixin z-character-style-1
	@include z-metric
	font-style: normal
	font-weight: 700
	font-size: 12px
	line-height: 16px
	letter-spacing: .43px
	color: var(--light-grey)

@mixin z-character-style-2
	font-family: var(--unnamed-font-family-nexity)
	font-style: normal
	font-weight: 700
	font-size: var(--unnamed-font-size-18)
	line-height: 16px
	letter-spacing: .65px
