@use './abstract' as *

.plan_page
	flex: 1
	
	max-width: 100%
	max-height: 100%
	
	display: flex
	
	.image
		margin: 4rem
		flex: 1
		
		position: relative
		max-width: 100%
		max-height: 100%
		overflow: hidden
		
		display: flex
		
		img
			object-fit: contain
			width: 100%
	
	@include zi-media
		.image
			margin: 0
