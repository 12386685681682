@use '../../abstract' as *

.contact_us
	position: relative
	
	display: flex
	flex-direction: column
	gap: 3rem
	
	padding: 2rem 1rem
	flex: 1
	background-color: white
	
	overflow-y: auto
	@include scrollbar-style
	
	// Pointer events
	pointer-events: auto
	
	.close_button
		text-decoration: underline
		
		cursor: pointer
		
		position: absolute
		top: 0
		right: 0
		padding: .5rem 1rem
		
		display: flex
		flex-direction: row
		gap: .5rem
		justify-content: center
		align-items: center
	
	.phone_section
		display: flex
		flex-direction: column
		gap: 1rem
		
		.title
			@include z-metric(40px, 40px, 0)
			font-weight: 500
		
		.phone_number
			display: flex
			flex-direction: column
			gap: .5rem
			
			.number
				@include z-nexity(32px, 32px, 0)
				font-weight: 700
				color: #16B14B
			
			.phone_description
				@include z-metric(14px, 14px, 0)
				font-weight: 500
		
		.description
			@include z-metric
			font-weight: 500
			color: $z-color-hard-grey
	
	.toggles
		display: flex
		flex-direction: column
		gap: 0
		
		> .toggle
			position: relative
			
			background-color: white
			padding: 1rem
			
			&:first-child
				&:before
					content: ''
					position: absolute
					top: 0
					left: 0
					width: 100%
					height: 1px
					background-color: $z-color-black
			
			&:after
				content: ''
				position: absolute
				bottom: 0
				left: 0
				width: 100%
				height: 1px
				background-color: $z-color-black
			
			.top
				display: flex
				flex-direction: row
				gap: 2rem
				height: fit-content
				
				cursor: pointer
				
				.title
					flex: 1
					@include z-metric-18
					font-weight: 700
				
				.chevron
					color: $z-color-major-red
					transition: transform .3s ease
			
			&.call_back
				.bottom
					display: flex
					flex-direction: column
					gap: 1rem
					
					.form
						display: flex
						flex-direction: column
						gap: 1rem
						
						.phone_number
							display: flex
							flex-direction: column
							gap: .5rem
							
							.input
								position: relative
								font-size: 20px
								
								.icon
									position: absolute
									top: 50%
									right: 1rem
									transform: translateY(-50%)
									
									color: $z-color-hard-grey
						
						.validate_button
							padding: 1rem 2rem
							
							background-color: $z-color-major-red
							color: white
							@include z-metric-24
							font-weight: 700
							
							width: fit-content
							align-self: center
			
			&.demande_information
				.bottom
					display: flex
					flex-direction: column
					gap: 2rem
					
					label
						display: flex
						flex-direction: column
						gap: .25rem
						
						.text
							@include z-metric-18
							font-weight: 500
					
					.checkbox_accept_data
						display: flex
						flex-direction: row
						gap: 1rem
					
					.choice_receive_personalised_offers, .choice_receive_personalised_partners_offers
						display: flex
						flex-direction: row
						justify-content: space-between
						gap: 1rem
						
						.choices
							display: flex
							flex-direction: row
							gap: .5rem
							
							.choice
								display: flex
								flex-direction: column
								justify-content: center
								align-items: center
								gap: .5rem
					
					.legal_notices
						padding: .5rem 1rem
						background-color: $z-color-light-grey
					
					.send_button
						padding: 1rem 2rem
						
						background-color: $z-color-major-red
						color: white
						@include z-metric-24
						font-weight: 700
						
						width: fit-content
						align-self: center
			
			&:not(.opened)
				.bottom
					display: none
			
			&.opened
				.top
					.chevron
						transform: rotate(180deg)
