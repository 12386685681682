@use '../../abstract' as *
.apartments_content
	padding: 0
	gap: 0
	
	width: 200%
	max-width: 200%
	display: flex
	flex-direction: row
	
	height: 100%
	
	> *
		min-width: 50%
		width: 50%
		max-width: 50%
		
		height: 100%
	
	.building_pane
		display: flex
		flex-direction: column
		
		> *
			background-color: white
			
			// Pointer events
			pointer-events: auto
		
		.apartments_global_information
			width: 200%
			display: flex
			flex-direction: row
			
			@include z-metric(18px, 24px, 90)
			
			.no_filter, .filter
				flex: 1
				width: 100%
				
				padding: 1.5rem 2rem
				background-color: $z-color-yellow
				
				display: grid
				grid: 'number text' 'number surface' 'number price' / auto 1fr
				gap: 0 2rem
				
				.number_of_apartments
					align-self: center
					justify-self: center
					
					grid-area: number
					@include z-nexity(48px, 48px, 120)
					font-weight: 700
					
					color: $z-color-major-red
				
				.text
					grid-area: text
				
				em
					color: $z-color-major-red
					font-style: normal
					font-weight: 600
				
				.price_interval
					em
						@include z-metric(20px, 20px)
			
			.no_filter
				order: 2
			
			.filter
				order: 1
		
		.filter_available_apartments
			padding: 1rem
			
			display: flex
			flex-direction: column
			gap: 1.5rem
			
			> .title
				@include z-nexity(24px, 16px, 60)
				font-weight: 700
			
			.type, .stage
				display: flex
				flex-direction: column
				gap: .5rem
				
				> .title
					text-decoration: underline
					@include z-metric(18px, 24px, 90)
					font-weight: 500
				
				.list
					display: flex
					flex-flow: row wrap
					gap: .25rem
					
					.item
						padding: 1rem 1.25rem
						border: 2px solid $z-color-black
						color: $z-color-black
						
						cursor: pointer
						
						// Font
						@include z-metric(16px, 16px, 0)
						font-weight: 500
						
						&.disabled
							cursor: not-allowed
							//opacity: .5
							border-color: #B2B2B2
							color: #B2B2B2
						
						&.selected
							background-color: $z-color-major-red
							color: white
							border-color: white
	
	.apartment_pane
		z-index: 2
		
		> *
			background-color: white
			
			// Pointer events
			pointer-events: auto
		
		.general_apartment_information
			display: flex
			flex-direction: row
			
			.go_back_button
				cursor: pointer
				display: flex
				align-items: center
				
				padding: 1rem
				
				font-size: 1.5rem
				
				min-height: 100%
				background-color: #FFF385
			
			> .content
				flex: 1
				
				padding: 2rem
				background-color: #FCF6BF
				
				display: flex
				flex-direction: column
				
				// Font
				@include z-metric(18px, 24px, 90)
				
				.package_number
					color: $z-color-ui-warning
					
					font-weight: 500
				
				.type
					font-weight: 500
		
		.bottom_content
			padding: 1rem
			
			display: flex
			flex-direction: column
			gap: 1rem
			
			.rooms_selection
				display: flex
				flex-direction: column
				gap: 1rem
				
				.top_section
					display: flex
					flex-direction: row
					justify-content: space-between
					align-items: flex-end
					gap: 2rem
					
					.title
						@include z-nexity(24px, 16px, 60)
						font-weight: 700
					
					.recenter
						text-decoration: underline
						
						cursor: pointer
						
						// Font
						@include z-metric(16px, 16px, 0)
						font-weight: 500
				
				.list
					// 3 columns grid
					display: grid
					grid-template-columns: repeat(3, 1fr)
					gap: 4px
					
					.item
						display: flex
						justify-content: center
						align-items: center
						
						padding: 1rem
						
						border: 2px solid $z-color-black
						
						text-align: center
						
						cursor: pointer
						
						// Font
						@include z-metric(16px, 16px, 0)
						font-weight: 500
						
						&.selected
							border-color: white
							background-color: $z-color-major-red
							color: white
			
			.see_price_and_plan
				padding: 1rem 2rem
				
				background-color: $z-color-black
				color: white
				
				// Font
				@include z-metric(18px, 16px, 0)
				font-weight: 700
	
	@include zi-media('>')
		> *
			//height: 100%
			overflow: hidden
			overflow-y: auto
			@include scrollbar-style
	
	@include zi-media
		> *
			overflow: hidden
			overflow-y: auto
			
			@include scrollbar-style
		
		.building_pane
			.apartments_global_information
				.filter, .no_filter
					padding: 1rem 3rem
			
			.filter_available_apartments
				position: absolute
				bottom: 5rem
				right: calc(50% + 1rem)
				
				@include zi-media('<=', mobile)
					left: 1rem
				
				background-color: white
				
				border: 2px solid $z-color-black
				
				&:not(.is_shown)
					display: none
				
				.type, .stage
					padding-left: 1.5rem
		.apartment_pane
			display: flex
			flex-direction: column
			justify-content: space-between
			
			.general_apartment_information
				.go_back_button
					svg
						width: 1rem
						height: auto
						aspect-ratio: 1
				
				> .content
					padding: 1rem 2rem
			
			.bottom_content
				.rooms_selection
					position: absolute
					
					bottom: 6rem + 4rem
					right: 1rem
					
					@include zi-media('<=', mobile)
						left: calc(50% + 1rem)
					
					background-color: white
					padding: 1rem
					
					border: 2px solid $z-color-black
					
					&:not(.is_shown)
						display: none
					
