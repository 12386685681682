//region Colors
$z-color-kaki-bouton: #787C66
$z-color-kaki-fond: #A3A795
$z-color-blanc: #FFFFFF
$z-color-noir: #3A3A3A
$z-color-violet: #A897EE
$z-color-red: #CE3249
$z-color-blue-title: #7380EA
$z-color-blue-text: #648EAF
$z-color-blue-filter: #255A85

// New colors from V2
$z-color-major-red: #FF0A36
$z-color-black: #2B2B2B
$z-color-green: #7BFF9F
$z-color-lavander: #A594FF
$z-color-yellow: #FFF385
$z-color-ui-warning: #F8AB30
$z-color-light-grey: #F5F5F5
// More colors
$z-color-light-lavander: #CFC6FC
$z-color-light-green: #B9FCCC
$z-color-light-cyan: #B0ECFC
$z-color-light-yellow: #FCF6BF
$z-color-hard-grey: #9C9C9C
$z-color-light-teriary: #3ADDDB
$z-color-turkish: #3BDDDB
$z-color-tertiary: #009696
$z-color-pink: #FFC1F6
$z-color-light-pink: #FCDDF7
$z-color-medium-grey: #E2E2E2
//endregion
//region Backgrounds
$z-bg-light: #F2EFE6

//region Linear
$z-bg-linear-blue: linear-gradient(140.96deg, #7E8BEE 14.8%, #5E6FE4 89.51%)
$z-bg-linear-blue-2: linear-gradient(144.37deg, #5163E0 4.87%, #8893F0 93.04%)
//endregion
//endregion
