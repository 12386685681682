@use './abstract' as *

.page_content
	// This will allow the middle row to grow and take all the space available
	display: grid
	grid-template-rows: auto 1fr auto
	
	main
		overflow: hidden
		
		display: flex
		flex-direction: row
		justify-content: flex-end
		
		height: 100%
		
		isolation: isolate
	
	&.is_plan_page
		background-color: white
	
	@include zi-media('>')
		height: 100vh
	
	@include zi-media
		main
			//display: grid
			flex-direction: column
		
		&.fullscreen
			height: 100vh
			max-height: 100vh
			height: 100dvh
			max-height: 100dvh
		
		// Make page content of plan page height auto and overlay full height
		&.is_plan_page
			main
				> :last-child
					flex: unset
					height: auto
				
				> :not(:last-child)
					height: 100%
					overflow: hidden
