@use '../../abstract' as *

.neighborhood_content
	display: flex
	flex-direction: column
	gap: 2rem
	
	padding: 1.25rem 1rem
	
	.title
		@include z-nexity(18px, 16px)
		font-weight: 700
	
	.filters
		display: grid
		grid-template-columns: repeat(3, 1fr)
		// Align items center while maintaining 1fr width
		margin-inline: auto
		
		min-width: 90%
		
		justify-content: center
		align-items: center
		
		gap: 2.5rem 1rem
		
		.item
			width: fit-content
			
			display: flex
			flex-direction: column
			gap: .5rem
			justify-content: center
			align-items: center
			overflow: hidden
			
			.top_colored
				display: flex
				flex-direction: column
				gap: .5rem
				justify-content: center
				align-items: center
				
				width: 6rem
				height: auto
				aspect-ratio: 1
				
				position: relative
				
				.icon
					object-fit: contain
					
					width: 3rem
					height: auto
					aspect-ratio: 1
				
				.checkbox
					width: 1.5rem
				
				.selected_overlay
					position: absolute
					inset: 0
					
					display: flex
					justify-content: center
					align-items: center
					
					font-size: 2rem
					
					background-color: $z-color-major-red
					color: white
			
			.title
				// Font
				@include z-metric(14px, 16px, 35)
				font-weight: 400
			
			&.education
				.top_colored
					.icon
						width: 41px
						height: 26px
			
			&.commerce
				.top_colored
					.icon
						height: 36px
			
			&.sante
				.top_colored
					.icon
						height: 30.6px
			
			&.sortie
				.top_colored
					.icon
						height: 35.6px
						transform: translateX(-5px)
			
			&.transports
				.top_colored
					.icon
						height: 34.6px
	
	.networks
		display: flex
		flex-direction: column
		gap: 1rem
		
		padding: 10px
		padding-bottom: 20px
		
		background-color: $z-color-light-grey
		
		> .title
			// Font
			@include z-metric(14px, 16px, 35)
			font-weight: 400
		
		.list
			flex: 1
			
			display: flex
			flex-direction: row
			gap: 1rem
			
			.item
				display: flex
				flex-direction: column
				gap: 2px
				justify-content: flex-end
				align-items: center
				
				width: 3rem
				height: auto
				aspect-ratio: 1
				
				box-shadow: 0 0 0 2px $z-color-black
				
				.icon
					width: 1.5rem
				
				&:nth-child(n+4)
					gap: 0
					
					.icon
						width: 2rem
				
				.title
					// Font
					@include z-metric(11px, 16px, 28)
					font-weight: 400
	
	@include zi-media
		position: absolute
		bottom: 5rem
		right: 1rem
		@include zi-media('<=', mobile)
			left: 1rem
		
		pointer-events: all
		
		background-color: white
		padding: 1rem
		
		border: 2px solid $z-color-black
		
		&:not(.is_shown)
			display: none !important
