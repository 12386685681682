@use './abstract' as *

//region Fonts
//region Nexity fonts
@font-face
	font-family: 'Nexity'
	src: url('./fonts/FS_Joey/FS Joey-Regular.otf')

@font-face
	font-family: 'Nexity'
	src: url('./fonts/FS_Joey/FS Joey-Heavy.otf')
	font-weight: 900

@font-face
	font-family: 'NexityBold'
	src: url('./fonts/FS_Joey/FS Joey-Bold.otf')
	font-weight: 500
//endregion
//region Metric fonts
@font-face
	font-family: 'Metric'
	src: url('./fonts/Metric/Metric-Regular.otf')

@font-face
	font-family: 'Metric'
	src: url('./fonts/Metric/Metric-Bold.otf')
	font-weight: 700

@font-face
	font-family: 'Metric'
	src: url('./fonts/Metric/Metric-Light.otf')
	font-weight: 300

@font-face
	font-family: 'Metric'
	src: url('./fonts/Metric/Metric-Medium.otf')
	font-weight: 500

@font-face
	font-family: 'Metric'
	src: url('./fonts/Metric/Metric-Thin.otf')
	font-weight: 100

@font-face
	font-family: 'Metric'
	src: url('./fonts/Metric/Metric-Semibold.otf')
	font-weight: 600
//endregion
//endregion
//region Default style
body
	margin: 0
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

	// Font
	@include z-metric(16px, 16px, 0)

code
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

.product-sheet--sticky
	display: none

.gs-dev-tools
	z-index: 999

button
	font-size: 1em

h1, h2, h3, h4, h5, h6
	margin: 0

*
	box-sizing: border-box
//endregion
//region Style
body
	//color: white
	color: $z-color-black

a
	text-decoration: none
	color: unset

button
	border: none
	cursor: pointer
	background-color: transparent

.z-3d-anchor
	.tmbnl--item--picture
		background: $z-bg-linear-blue-2

	.tmbnl--item--icon
		color: white
		font-weight: 700

#z_three_js
	position: relative

	#z_three_js_container__anchor
		position: absolute
		top: -5rem

#z_three_js_container
	font-family: 'Nexity', 'sans-serif'
	max-height: 100vh
	height: 100vh
	min-height: 100svh
	height: 100dvh
	max-height: 100dvh

	display: flex
	flex-direction: column

	@include zi-media('>', tablet)
		max-height: 100vh
		overflow: hidden

	canvas
		width: 100%
		height: 100%
		max-height: 100vh

.fullscreen_section
	flex: 1

	display: flex
	flex-direction: column

	width: 100%
	height: 100%

	position: relative

	@include zi-media
		position: absolute
		inset: 0

		z-index: -1

input, textarea
	@include z-metric-18
	width: 100%
	padding: .5rem 1rem
	border-radius: 0
	border: 2px solid $z-color-black

	&[type=checkbox]
		width: fit-content
//endregion

//region iFrame
iframe
	flex: 1

	width: 100%
	height: 100%
//endregion

.neighboor
	color: black
	font-size: 2rem

	display: flex
	justify-content: center
	align-items: center

	// Background
	--gap-color: white
	--gap-size: 4px
	--line-color: #e0c6c6
	--line-size: 2px

	background: repeating-linear-gradient(45deg, var(--gap-color) 0, var(--gap-color) var(--gap-size), var(--line-color) var(--gap-size), var(--line-color) calc(var(--gap-size) + var(--line-size)))

.corridor
	color: black
	font-size: 2rem

	display: flex
	justify-content: center
	align-items: center

	background-color: #e5dcdc

//region Grid background
@mixin z-grid-background($lines_color: white, $square_color: transparent, $lines_size: 1px, $square_size: 80px)
	position: relative
	isolation: isolate

	&:before
		content: ''
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)

		width: 100%
		height: 100%

		background: repeating-linear-gradient($square_color, $square_color calc($square_size / 2), $lines_color calc($square_size / 2), $lines_color calc($square_size / 2 + $lines_size), $square_color calc($square_size / 2 + $lines_size), $square_color calc($square_size - $lines_size)), repeating-linear-gradient(.25turn, $square_color, $square_color calc($square_size / 2), $lines_color calc($square_size / 2), $lines_color calc($square_size / 2 + $lines_size), $square_color calc($square_size / 2 + $lines_size), $square_color calc($square_size - $lines_size))

		z-index: -1

.fullscreen_section
	//background: linear-gradient(#EBE7DB, #eeede8)

	&.grid_css
		@include z-grid-background()
//endregion

//region Mapbox
#map-container
	height: 100%

	.mapboxgl-canvas-container
		height: 100%

		canvas
			height: 100%

		.mapboxgl-marker
			&.hidden
				opacity: 0 !important
//endregion
