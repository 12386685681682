@use '../../abstract' as *
.price_and_plan
	color: #2b2b2b
	
	display: flex
	flex-direction: column
	gap: 4rem
	
	.close_button
		font-size: 1rem
		position: absolute
		top: 2rem
		right: 2rem
		
		cursor: pointer
	
	.phone_section
		display: flex
		flex-direction: column
		
		> .title
			@include z-nexity(24px, 24px, 0)
		
		.phone_number
			font-family: NexityBold, sans-serif
			font-size: 48px
			line-height: 1em
			color: #11d16a
		
		.description
			@include z-nexity(15px, 15px, 0)
	
	.demande_information
		background-color: #f5f5f5
		padding: 2rem
		
		@include zi-media
			padding: 1rem
		
		display: flex
		flex-direction: column
		gap: 2rem
		
		> .title
			@include z-nexity(24px, 24px, 0)
		
		.form
			display: grid
			grid-template-columns: repeat(2, 1fr)
			gap: 1.5rem
			
			> *
				grid-column: 1 / -1
				width: 100%
			
			.first_name, .last_name, .email_address, .phone_number
				@include zi-media('>')
					grid-column: auto
			
			.first_name, .last_name, .email_address, .phone_number, .message
				display: flex
				flex-direction: column
				gap: 4px
				width: auto
				
				// Font
				@include z-nexity(15px, 15px, 0)
				
				input, textarea
					padding: 0 5px
					border-radius: 4px
					border: 1px solid #000
					width: 100%
				
				input
					height: 38px
				
				textarea
					min-height: 56px
			
			.checkbox_accept_data
				display: flex
				flex-direction: row
				gap: .5rem
				align-items: flex-start
				
				@include z-nexity(13px, 13px, 0)
			
			.choice_receive_personalised_offers, .choice_receive_personalised_partners_offers
				display: flex
				flex-direction: row
				justify-content: space-between
				
				@include z-nexity(14px, 14px, 0)
				
				.text
					flex: 1
				
				.choices
					display: flex
					flex-direction: row
					gap: .5rem
			
			.send_button
				@include z-nexity(16px, 16px, 0)
				
				background-color: #2b2b2b
				color: white
				
				width: fit-content
				justify-self: center
				
				padding: 1rem 2rem
			
			.legal_notices
				display: flex
				flex-direction: column
				gap: 0
				@include z-nexity(12px, 12px, 0)
