@use '../../../abstract/index' as *

.camera_movements_overlay
	position: absolute
	bottom: 5rem
	right: 1rem
	
	background-color: var(--background-color)
	
	overflow: hidden
	
	display: flex
	flex-direction: row
	
	border-radius: 10px
	padding: .5rem
	
	box-shadow: 0 0 0 2px $z-color-black
	
	.content
		display: flex
		flex-direction: column
		position: relative
		
		.icons
			display: flex
			flex-direction: row
			gap: 1rem
			
			$transform-value: 6px
			
			.icon
				padding: .5rem
				
				transform: rotateX(45deg)
				
				position: relative
				isolation: isolate
				
				cursor: pointer
				
				svg
					transition: transform .3s ease
				
				&:after
					content: ''
					position: absolute
					inset: 0
					z-index: -1
					border-radius: 999px
					background-color: white
					box-shadow: 0 0 0 2px $z-color-black
					
					transition: transform .3s ease
				
				&:before
					content: ''
					position: absolute
					inset: auto 0 #{-$transform-value} 0
					height: 100%
					border-radius: 999px
					background-color: white
					z-index: -2
					
					box-shadow: 0 0 0 2px $z-color-black
				
				&:hover
					&:before
						background-color: $z-color-black
					
					&:after
						background-color: #FFF385
						transform: translateY($transform-value)
					
					svg
						transform: translateY($transform-value)
		
		> .text
			position: absolute
			bottom: 0
			width: 100%
			display: flex
			justify-content: center
			
			overflow: hidden
			user-select: none
			
			span
				width: fit-content
				@include z-nexity(10px)
				font-weight: 700
				position: relative
				padding-inline: .5rem
				
				&:before, &:after
					content: ''
					position: absolute
					top: 50%
					transform: translateY(-50%)
					height: 1px
					background-color: $z-color-black
				
				&:before
					left: -1000px
					right: 100%
				
				&:after
					left: 100%
					right: -1000px
	
	.opener
		display: flex
		justify-content: center
		align-items: center
		height: 40px
		width: auto
		aspect-ratio: 1
		
		z-index: 2
		
		background-color: var(--background-color)
		box-shadow: 0 0 0 .5rem var(--background-color)
		border-radius: 4px
	
	@include zi-media
		bottom: 6.5rem
