@use '../../../abstract/index' as *

.contact_overlay
	position: absolute
	bottom: 6.5rem + 2.5rem
	right: 1rem
	
	overflow: hidden
	
	background-color: #16B14B
	
	display: flex
	flex-direction: row
	
	border-radius: 10px
	padding: 1rem
	
	box-shadow: 0 0 0 2px #fefefe
	
	.icon
		font-size: 24px
		fill: #fefefe
	
	@include zi-media
		bottom: 6.5rem + 4rem
		
		&.information
			bottom: 6.5rem
