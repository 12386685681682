@use './abstract' as *

.overlay
	display: flex
	flex-direction: column
	gap: 0
	
	> .top
		display: flex
		flex-direction: row
		gap: 0
		
		height: 100%
		overflow: hidden
		
		width: 100%
		
		.tabs
			display: flex
			flex-direction: column
			gap: 2rem
			
			flex: 0
			
			background-color: $z-color-major-red
			
			// Font
			@include z-metric(14px, 14px, 70)
			font-weight: 700
			
			> *
				width: 100%
				color: white
				
				padding: .5rem
				
				cursor: pointer
				
				display: flex
				flex-direction: column
				gap: .5rem
				justify-content: center
				align-items: center
				
				white-space: nowrap
				
				.icon
					display: flex
					justify-content: center
					align-items: center
					
					padding: .25rem .5rem
					
					fill: white
					
					width: 100%
					height: 2rem
				
				&.active
					.icon
						fill: $z-color-major-red
						background-color: white
						border-radius: 999px
		
		.default_overlay
			width: 100%
			height: 100%
			flex: 1
			overflow: hidden
			
			display: flex
			flex-direction: column
			
			&.is_contact_us
				.tabs
					display: none
				
				> .content
					.bottom_section
						display: none
				
				@include zi-media
					position: fixed
					inset: 0
					height: 100%
					z-index: 10
			
			> .content
				overflow: hidden
				
				display: flex
				flex-direction: column
				
				height: 100%
				
				@include zi-media('>', tablet)
					background-color: white
				
				.program_content
					display: flex
					flex-direction: column
					gap: 3rem
					
					padding: 2rem 1rem
					flex: 1
					background-color: white
					
					overflow-y: auto
					@include scrollbar-style
					
					h4
						// Font
						@include z-metric(18px, 24px, 36)
						font-weight: 700
					
					.main_information
						display: flex
						flex-direction: column
						
						.apartment_name
							// Font
							@include z-nexity(32px, 32px, 0)
							font-weight: 700
						
						.sub_description
							@include z-metric(16px, 32px, 0)
							font-weight: 500
							color: $z-color-hard-grey
					
					.first_trimester
						display: flex
						flex-direction: row
						align-items: center
						gap: .5rem
						padding: .5rem 1rem
						
						border: 1px solid black
						border-radius: 999px
						width: fit-content
						
						@include z-nexity(14px, 14px, 0)
						
						svg
							width: 1rem
					
					.apartment_description
						@include z-metric(16px, 24px, 0)
						font-weight: 400
						
						strong
							font-weight: 500
						
						&:not(.full)
							overflow: hidden
							max-height: 40ch
							min-height: 10rem
							
							position: relative
							
							.read_more
								width: 100%
								
								position: absolute
								inset: auto 0 0
								
								padding: 2rem
								
								display: flex
								justify-content: center
								align-items: center
								
								background: linear-gradient(to bottom, rgba(white, .8) 20%, white 50%)
								
								cursor: pointer
								
								@include z-nexity(14px, 14px, 0)
								text-decoration: underline
								font-weight: 700
					
					.available_lot
						display: flex
						flex-direction: column
						align-items: center
						gap: 1rem
						
						@include z-metric-24
						font-weight: 500
						
						text-align: center
						
						strong
							color: $z-color-major-red
						
						.choose
							width: 80%
							background-color: $z-color-major-red
							color: white
							
							padding: 1rem 2rem
							font-size: 1.25rem
							
							display: flex
							justify-content: center
							align-items: center
					
					.gallery
						display: flex
						flex-direction: column
						gap: 1rem
						
						.title
							@include z-nexity(18px, 18px, 0)
							font-weight: 700
						
						.swiper
							max-width: 100%
							
							display: flex
							flex-direction: column
							gap: 1rem
							
							.bullet
								background-color: transparent
								border: 2px solid black
								width: .75rem
								height: auto
								aspect-ratio: 1
								
								opacity: 1
							
							.bullet_active
								background-color: black
							
							> *
								position: relative
								
								.icon
									position: absolute
									top: .5rem
									left: .5rem
									
									display: flex
									justify-content: center
									align-items: center
									
									padding: .5rem
									
									background-color: white
									border-radius: 999px
									
									.camera_icon
										font-size: 1rem
							
							img
								width: 100%
								height: auto
								aspect-ratio: 1
								object-fit: cover
								
								border: 2px solid black
					
					.characteristics
						display: flex
						flex-direction: column
						gap: 1rem
						
						.list
							display: grid
							grid-template-columns: repeat(3, 1fr)
							gap: 1rem
							
							.item
								display: flex
								flex-direction: column
								gap: .5rem
								justify-content: center
								align-items: center
								text-align: center
								
								// Font
								@include z-metric(14px, 20px, 70)
							
							.icon
								max-width: 3rem
								height: auto
						
						.options_description
							@include z-metric(12px, 24px, 30)
							font-weight: 500
					
					.nearby_transport
						display: flex
						flex-direction: column
						gap: 1rem
						
						.list
							display: grid
							grid-template-columns: repeat(3, 1fr)
							gap: 1rem
							
							.item
								display: flex
								flex-direction: column
								gap: .5rem
								justify-content: center
								align-items: center
								text-align: center
								
								// Font
								@include z-metric(14px, 20px, 70)
							
							.image
								max-width: 3rem
								height: auto
					
					.nexity_plus
						display: flex
						flex-direction: column
						gap: 1rem
						
						.list
							display: flex
							flex-direction: column
							gap: .5rem
							
							.item
								padding: 1rem
								
								display: grid
								grid: 'image title' 'image description' / auto 1fr
								gap: .5rem 2rem
								
								.icon
									grid-area: image
								
								.title
									grid-area: title
									
									// Font
									@include z-metric(18px, 22px, 45)
									font-weight: 700
					
					.contact_us
						display: flex
						flex-direction: column
						align-items: center
						gap: 1rem
						
						@include z-nexity(24px, 24px, 0)
						font-weight: 700
						
						text-align: center
						
						.button
							width: 80%
							background-color: $z-color-major-red
							color: white
							
							@include z-nexity(24px, 24px, 0)
							font-weight: 700
							
							padding: 1rem 2rem
							font-size: 1.25rem
							
							display: flex
							justify-content: center
							align-items: center
			
			&.is_apartment
				> .content
					.bottom_section
						display: none
		
		.contact_form
			grid-area: content
			
			background-color: white
			border: 2px solid $z-color-black
			
			display: flex
			flex-direction: column
			
			// Pointer events
			pointer-events: auto
			
			overflow: hidden
			
			.top
				padding: 1rem 2rem
				background-color: $z-color-light-grey
				
				display: flex
				flex-direction: row
				gap: 2rem
				justify-content: space-between
				align-items: center
				
				.title
					@include z-nexity(32px, 32px, 0)
					font-weight: 700
				
				.close_button
					
					cursor: pointer
					
					// Font
					@include z-metric(18px, 18px, 0)
					font-weight: 700
					text-decoration: underline
			
			.steps
				display: grid
				grid-template-columns: repeat(4, 1fr)
				
				overflow: hidden
				
				> *
					background: white
					padding: 1rem
					
					display: flex
					flex-direction: column
					gap: 50px
					
					overflow-y: auto
					@include scrollbar-style
					
					.main_content
						display: flex
						flex-direction: column
						justify-content: center
						gap: 1rem
						
						// Font
						@include z-metric(18px, 24px, 0)
						
						label
							display: flex
							flex-direction: column
							
							.text
								@include z-metric-18
								font-weight: 500
						
						.checkbox_accept_data
							display: flex
							flex-direction: row
							gap: 1rem
							
							.text
								font-weight: 400
						
						.input_field
							display: flex
							flex-direction: column
							gap: 4px
							
							label
								font-weight: 500
							
							input
								padding: 1rem
								
								// Custom border
								border: none
								box-shadow: 0 0 0 2px $z-color-black
								transition: box-shadow .3s ease
								
								// Focus custom border style
								&:focus
									outline: none
									box-shadow: 0 0 0 2px $z-color-major-red
						
						.checkbox_field
							display: flex
							flex-direction: row
							gap: 2rem
							align-items: flex-start
							
							.text
								flex: 1
								
								// Font
								@include z-metric(14px, 16px, 35)
					
					> .title
						// Font
						@include z-metric(24px, 24px, 60)
						font-weight: 700
					
					.questions
						display: flex
						flex-direction: column
						gap: 25px
						
						// Font
						@include z-nexity(14px, 16px, 35)
						
						> *
							display: flex
							flex-direction: row
							
							gap: 2rem
							justify-content: space-between
							
							.choices
								display: flex
								flex-direction: row
								gap: 1rem
								
								label
									display: flex
									flex-direction: column
									gap: .25rem
									
									cursor: pointer
									
									// Font
									@include z-nexity(14px, 16px, 35)
									
									input[type=radio]
										cursor: pointer
					
					.next_button
						align-self: center
						
						width: fit-content
						padding: 1rem 1.5rem
						background-color: $z-color-major-red
						color: white
						
						// Font
						@include z-nexity(18px, 18px, 35)
						font-weight: 700
					
					.choice_receive_personalised_offers, .choice_receive_personalised_partners_offers
						display: flex
						flex-direction: row
						justify-content: space-between
						gap: 1rem
						
						.choices
							display: flex
							flex-direction: row
							gap: .5rem
							
							.choice
								display: flex
								flex-direction: column
								justify-content: center
								align-items: center
								gap: .5rem
					
					.legal_notices
						padding: .5rem 1rem
						background-color: $z-color-light-grey
				
				.step_2
					.main_content
						justify-content: flex-start
				
				.last_step
					gap: 2rem
					padding: 0
					
					.main_content
						justify-content: flex-start
						
						.thank
							text-align: center
							padding: 1rem .5rem
							
							// Font
							@include z-metric(18px, 24px, 90)
							font-weight: 400
						
						.apartment_information
							display: flex
							flex-direction: column
							gap: .5rem
							
							padding: 1.5rem 2.5rem 2.5rem
							background-color: #FCF6BF
							
							// Font
							@include z-metric(18px, 24px, 90)
							
							.package_number
								color: $z-color-ui-warning
								
								font-weight: 500
							
							.type
								font-weight: 500
							
							.data
								display: flex
								flex-flow: row wrap
								--gap-value: 1.5rem
								gap: 4px var(--gap-value)
								
								:not(:last-child)
									position: relative
									
									&:after
										content: "•"
										
										position: absolute
										top: 50%
										right: calc(var(--gap-value) / -2)
										transform: translate(50%, -50%)
										
										font-size: 1.5rem
										font-weight: 700
							
							.price
								display: flex
								flex-direction: row
								gap: .5rem
								align-items: center
								
								.value
									font-size: 1.5rem
									font-weight: 900
						
						.download_plan_in_pdf
							text-align: center
							margin: 1rem
							padding: 2rem 3rem 1.5rem
							background-color: $z-color-black
							color: white
							
							// Font
							@include z-metric(18px, 16px, 45)
							font-weight: 700
					
					.go_back_to_3d_view
						align-self: center
						justify-self: center
						
						width: fit-content
						
						margin-bottom: 2rem
						
						position: relative
						
						cursor: pointer
						
						// Font
						@include z-metric(14px, 16px, 35)
						font-weight: 700
						
						&:after
							content: ""
							
							position: absolute
							bottom: -4px
							left: 50%
							transform: translate(-50%, 0)
							width: 100%
							height: 2px
							
							background-color: $z-color-black
	
	.footer
		height: 1.5rem
		min-height: 1.5rem
		
		background-color: white
		
		display: flex
		flex-direction: row
		align-items: center
		gap: 2rem
		
		// Cursor
		> *
			cursor: pointer
		
		// Spacer
		padding-left: 1.5rem
		padding-right: 12px
		
		// Font
		@include z-nexity(12px, 12px, 0)
		
		// Divider (not on first and last child)
		:not(:first-child, :last-child)
			position: relative
			
			&::after
				content: "/"
				position: absolute
				right: -1rem
				transform: translateX(50%)
				
				// Remove cursor
				cursor: default
		
		// Home icon
		.home_icon
			font-size: 1rem
			
			// Decrease gap
			margin-right: -1rem
		
		// Current tab
		.current_tab
			display: flex
			flex-direction: row
			
			justify-content: center
			align-items: center
			
			gap: .5rem
			
			// Font
			@include z-metric(12px, 12px, 0px)
			font-weight: 500
	
	@include zi-media('>')
		z-index: 10
		
		height: 100%
		
		width: 33%
		min-width: 500px
		max-width: 600px
		
		> .top
			flex: 1
			
			.tabs
				height: 100%
				
				.information
					display: none
			
			.default_overlay
				height: 100%
				
				> .content
					height: 100%
					
					display: flex
					flex-direction: column
					
					> .program_content
						//height: 100%
						overflow: hidden
						overflow-y: auto
						@include scrollbar-style
			
			.contact_form
				height: 100%
				
				overflow: hidden
				
				> .steps
					max-height: 100%
					flex: 1
					overflow: hidden
					
					> *
						overflow: hidden
						overflow-y: auto
						@include scrollbar-style
	
	@include zi-media
		order: 2
		max-height: 100%
		height: 100%
		
		// Remove pointer events
		pointer-events: none
		
		.top
			display: flex
			flex-direction: column
			justify-content: space-between
			
			> .tabs
				display: flex
				flex-direction: row
				justify-content: space-between
				
				// Pointer events
				pointer-events: auto
				
				// Font
				@include z-metric(12px)
				font-weight: 500
				
				gap: 10px
				
				order: 10
			
			.default_overlay
				> .content
					display: flex
					flex-direction: column
					
					.program_content
						pointer-events: auto
					
					> .content
						height: 100%
			
			.contact_form
				
				&:not(.is_last_step)
					position: fixed
					inset: 0
					z-index: 10
				
				&.is_last_step
					overflow: hidden
					
					.steps
						> *
						
						.last_step
							overflow-y: auto
							min-height: 100%
							
							@include scrollbar-style
				
				.steps
					> *
						padding: 2rem 1rem
						
						.main_content
							flex: unset
					
					.last_step
						padding: 0
						overflow: hidden
						
						justify-content: space-between
						gap: 1rem
						
						.main_content
							padding: 2rem 1rem 0
							gap: 1rem
							
							.thank
								padding: 0
						
						.go_back_to_3d_view
							display: flex
							justify-content: center
							align-items: center
							
							width: 100%
							margin: 0
							padding: 2rem
							
							background-color: $z-color-light-grey
