@use './abstract' as *

.header
	display: grid
	grid: 'logo program information_and_reservation links' / auto auto 1fr auto
	align-items: center
	gap: 6rem
	
	max-height: 4rem
	height: 4rem
	
	padding-left: 1.5rem
	
	background-color: white
	
	.logo
		display: flex
		flex-direction: row
		justify-content: center
		align-items: center
		gap: 2rem
		
		grid-area: logo
		
		img
			max-height: 50px
		
		.back_button
			display: flex
			flex-direction: row
			gap: .5rem
			
			cursor: pointer
			
			justify-content: center
			align-items: center
	
	.program
		grid-area: program
		
		display: flex
		flex-direction: column
		
		.name
			@include z-metric-24
			font-weight: 700
		
		.description
			color: $z-color-hard-grey
			@include z-metric-18
	
	.information_and_reservation
		grid-area: information_and_reservation
		
		display: flex
		flex-direction: row
		align-items: center
		justify-content: center
		justify-self: flex-end
		gap: 1rem
		
		.title
			@include z-metric(14px)
			font-weight: 700
		
		.phone_number
			@include z-nexity(32px)
			font-weight: 700
			color: #14B14B
	
	.links
		grid-area: links
		
		display: flex
		flex-direction: row
		justify-content: flex-end
		align-self: stretch
		background-color: blue
		
		> *
			display: flex
			flex-direction: column
			gap: 12px
			
			justify-content: flex-end
			align-items: center
			
			height: 4rem
			width: 4rem
			
			color: white
			background-color: $z-color-major-red
			
			cursor: pointer
			
			.icon
				font-size: 24px
				margin-top: 12px
			
			.text
				// Font
				@include z-metric(11px, 11px, 28)
				margin-bottom: 5px
		
		.menu
			background-color: $z-color-black
			height: 4rem
			width: auto
			aspect-ratio: 1
			
			padding-inline: 0
			
			.icon
				padding-inline: 20px
		
		.spacer
			padding: 0
			width: 1rem
			height: 100%
			
			background-color: $z-color-black
			
			cursor: unset
	
	@include zi-media
		display: flex
		flex-direction: row
		justify-content: space-between
		gap: 1.5rem
		
		height: unset
		max-height: unset
		
		padding: 4.5px 0 4.5px 8px
		
		z-index: 10
		
		.logo
			img
				max-height: 2.5rem
		
		.program
			display: flex
			flex-direction: column
			gap: 2px
			
			.name
				@include z-nexity(14px, 15px)
			
			.description
				@include z-metric(12px)
				font-weight: 500
		
		.filters
			display: none
		
		.information_and_reservation
			display: none
		
		.links
			> *:not(.menu)
				display: none
			
			.menu
				height: 3rem
				display: flex
				justify-content: center
				align-items: center
				
				.icon
					padding: 0
					margin: 0
				
				.text
					display: none
	@media (max-width: 1400px)
		gap: 2.5rem
		.information_and_reservation
			flex-direction: column
			gap: 0
			
			.title
				@include z-nexity(12px, 15px)
